@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --toastify-color-success: theme('colors.emerald.600');
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-color-progress-success: var(--toastify-color-success);

  --toastify-color-error: theme('colors.red.600');
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-color-progress-error: var(--toastify-color-error);

  --toastify-toast-width: 380px;
  --toastify-toast-min-height: 54px;
}

.react-datepicker__input-container input {
  @apply block w-full text-base md:text-sm bg-white border border-gray-200 rounded shadow-sm;
}

.react-datepicker-popper {
  @apply text-sm bg-transparent py-1 rounded;
}

.react-datepicker__header {
  @apply bg-white border-0;
}

.react-datepicker__month-container {
  @apply flex flex-col shadow-lg;
}

.react-datepicker__month {
  @apply flex flex-col mt-0;
}

.react-datepicker__current-month {
  @apply text-base font-semibold text-gray-900 pb-3;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-400 font-medium text-center text-xs;
}

.react-datepicker__day-name {
  @apply w-8 h-6 flex items-end justify-center rounded-full text-gray-700 font-semibold text-xs;
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-300;
}

.react-datepicker__day--selected {
  @apply bg-emerald-600 text-white;
}

.rdrDefinedRangesWrapper {
  display: none
}

.rdrDayToday .rdrDayNumber span:after {
  background: #16a34a
}
